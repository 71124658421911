import React, { Fragment } from "react"

import { useStaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet"

const SEO = props => {
  // START : Default settings
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { regex: "/barrels.jpg/" }) {
          childImageSharp {
            fixed(width: 1200) {
              src
            }
          }
        }
      }
    `
  )
  const siteImage = data.file.childImageSharp.fixed.src
  const siteTitle = "Amaury BEAUFORT"
  const siteDescription =
    "Artisan vinificateur à Bar sur Seine depuis 2019. Champagne et Bourgogne bio issus de vignes cultivées sans engrais chimique, sans désherbants, sans pesticides ni fongicides de synthèse depuis 1971."
  const siteAuthor = "Amaury BEAUFORT"
  const siteOrigin = "https://www.amaury-beaufort.com"
  const siteLang = "fr"

  // STOP : Default settings

  const pageUrl = siteOrigin + (props.slug ? props.slug : "") + "/"

  const metaImage = siteOrigin + (props.image || siteImage)
  const metaDescription =
    (props.description ? props.description + " | " : "") + siteDescription

  const metaTitle = (props.title ? props.title + " | " : "") + siteTitle
  const metaAuthor = siteAuthor

  // console.log("pageUrl", pageUrl)
  // console.log("metaTitle", metaTitle)
  // console.log("metaDescription", metaDescription)
  // console.log("metaImage", metaImage)
  // console.log("metaAuthor", metaAuthor)

  return (
    <Fragment>
      {pageUrl && metaTitle && metaDescription && metaImage && metaAuthor && (
        <Helmet>
          {/* START : General tags */}
          <html lang={props.lang || siteLang} />
          <title>{metaTitle}</title>
          <meta
            name="description"
            content={metaDescription.substring(0, 160 - 3) + "..."}
          />
          <link rel="canonical" href={pageUrl} />
          <link rel="image_src" href={metaImage} />
          {/* STOP : General tags */}

          {/* START : OpenGraph tags */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:title" content={metaTitle} />
          <meta
            property="og:description"
            content={metaDescription.substring(0, 200 - 3) + "..."}
          />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
          {/* STOP : OpenGraph tags */}

          {/* START : Twitter Card tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={metaTitle} />
          <meta
            name="twitter:description"
            content={metaDescription.substring(0, 200 - 3) + "..."}
          />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:creator" content={metaAuthor} />
          {/* STOP : Twitter Card tags */}
        </Helmet>
      )}
    </Fragment>
  )
}

export default SEO
